import wordpress from '../service/wordpress'
import portal from '../service/portal'
// const convert = require('xml-js')
export const strict = false

export const state = () => ({
  language: 'en',
  brands: [],
  news: [],
  barUsps: [],
  productGroups: [],
  universal: [],
  getUniversalMain: {},
  stock: [],
  cartItems: [],
  cartQty: 0,
  priceList: 16,
  retailPriceList: 16,
  cartChanged: false,
  bearer: null,
  cartStatus: false,
  favoriteItems: [],
  favoriteItemsFetched: false,
})

export const getters = {
  getCartStatus(state) {
    return state.cartStatus
  },
  getLanguage(state) {
    return state.language
  },
  getBrands(state) {
    return state.brands
  },
  getNews(state) {
    return state.news
  },
  getBarUsps(state) {
    return state.barUsps
  },
  getProductGroups(state) {
    return state.productGroups
  },
  getUniversal(state) {
    return state.universal
  },
  getUniversalMain(state) {
    return state.universalMain
  },
  getPriceList(state) {
    return state.priceList
  },
  getRetailPriceList(state) {
    return state.retailPriceList
  },
  getStock(state) {
    return state.stock
  },
  getCartItems(state) {
    return state.cartItems
  },
  getCartQty(state) {
    return state.cartQty
  },
  getCartChanged(state) {
    return state.cartChanged
  },
  getFavoriteItems(state, productID) {
    return state.favoriteItems;
  },
  getIsFavoritesSet(state) {
    return !!state.favoriteItemsFetched;
  }
}

export const mutations = {
  SET_CART_STATUS(state, val) {
    state.cartStatus = val
  },
  SET_LANGUAGE(state, language) {
    state.language = language
  },
  SET_BRANDS(state, brands) {
    state.brands = brands
  },
  SET_NEWS(state, news) {
    state.news = news
  },
  SET_BAR_USPS(state, barUsps) {
    state.barUsps = barUsps
  },
  SET_PRODUCT_GROUPS(state, productGroups) {
    state.productGroups = productGroups
  },
  SET_UNIVERSAL(state, universal) {
    state.universal = universal
  },
  SET_UNIVERSAL_MAIN(state, universalMain) {
    state.universalMain = universalMain
  },
  SET_PRICE_LIST(state, priceList) {
    state.priceList = priceList
  },
  SET_RETAIL_PRICE_LIST(state, priceList) {
    state.retailPriceList = priceList
  },
  SET_STOCK(state, stock) {
    state.stock = stock
  },
  SET_BEARER(state, bearer) {
    state.bearer = bearer
  },
  SET_CART_ITEMS(state, cartItems) {
    state.cartItems = cartItems
    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    let cartQty = 0
    for (let i = 0; i < cartItems.length; i++) {
      cartQty = cartQty + parseInt(cartItems[i].quantity)
    }
    state.cartQty = cartQty
  },
  SET_CART_ITEM(state, cartItem) {
    let newCartItems = []
    if (window.localStorage.getItem('cartItems') !== null) {
      newCartItems = JSON.parse(window.localStorage.getItem('cartItems'))
    }
    let exists = false
    for (let i = 0; i < newCartItems.length; i++) {
      if (newCartItems[i].product.id === cartItem.product.id) {
        newCartItems[i].quantity++
        exists = true
        i = newCartItems.length
      }
    }
    if (!exists) {
      const pushItem = cartItem
      pushItem.quantity = 1
      newCartItems.push(pushItem)
    }
    state.cartItems = newCartItems
    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    let cartQty = 0
    for (let i = 0; i < newCartItems.length; i++) {
      cartQty = cartQty + parseInt(newCartItems[i].quantity)
    }
    state.cartQty = cartQty
    state.cartChanged = true // !exists
  },
  CHANGE_CART_QUANTITY(state, cartItem) {
    let oldCartItems = []
    if (window.localStorage.getItem('cartItems') !== null) {
      oldCartItems = JSON.parse(window.localStorage.getItem('cartItems'))
    }
    oldCartItems[cartItem.index].quantity = cartItem.quantity
    state.cartItems = oldCartItems
    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    let cartQty = 0
    for (let i = 0; i < oldCartItems.length; i++) {
      cartQty = cartQty + parseInt(oldCartItems[i].quantity)
    }
    state.cartQty = cartQty
  },
  REMOVE_CART_ITEM(state, cartItem) {
    let oldCartItems = []
    const newCartItems = []
    if (window.localStorage.getItem('cartItems') !== null) {
      oldCartItems = JSON.parse(window.localStorage.getItem('cartItems'))
    }
    let exists = false
    for (let i = 0; i < oldCartItems.length; i++) {
      if (oldCartItems[i].product.id === cartItem.product.id) {
        exists = true
      } else {
        newCartItems.push(oldCartItems[i])
      }
    }
    state.cartItems = newCartItems
    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    let cartQty = 0
    for (let i = 0; i < newCartItems.length; i++) {
      cartQty = cartQty + parseInt(newCartItems[i].quantity)
    }
    state.cartQty = cartQty
    state.cartChanged = exists
  },
  REMOVE_ALL_CART_ITEM(state) {
    state.cartItems = []
    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    state.cartQty = state.cartItems.length
    state.cartChanged = true
  },
  SET_FAVORITE_ITEMS(state, productsIDs) {
    state.favoriteItems = productsIDs;
  },
  ADD_FAVORITE_ITEM(state, itemProductID) {
    state.favoriteItems.push(itemProductID);
  },
  REMOVE_FAVORITE_ITEM(state, itemProductID) {
    let itemOnListIndex = state.favoriteItems.indexOf(itemProductID);

    if (itemOnListIndex > -1) {
      state.favoriteItems.splice(itemOnListIndex, 1);
    }
  },
  REMOVE_ALL_FAVORITE_ITEMS(state) {
    state.favoriteItems = [];
  },
  SET_FAVORITE_ITEMS_FETCHED(state, isFetched) {
    state.favoriteItemsFetched = isFetched;
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { redirect, app, req }) {
    let language = req._parsedUrl.path.split('/')
    language.shift()
    language = language[0]

    if (language.length === 2) {
      app.$cookies.set('language', language, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      })
    } else if (language.length < 2) {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : 'en'
    } else {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : 'en'
      if (language === 'en') {
        redirect(`${req._parsedUrl.path}`)
      } else {
        redirect(`/${language}${req._parsedUrl.path}`)
      }
    }

    if (req._parsedUrl.path.length < 2) {
      if (language === 'en') {
        redirect(`/`)
      } else {
        redirect(`/${language}`)
      }
    }
    commit('SET_LANGUAGE', language);

    let currentPriceList = getUserPriceList(language, app.$auth);
    let currentRetailPriceList = currentPriceList;

    if (app.$auth.loggedIn) {
      currentRetailPriceList = getRetailPriceList(language, currentPriceList);
    }

    commit('SET_PRICE_LIST', currentPriceList);
    commit('SET_RETAIL_PRICE_LIST', currentRetailPriceList);

    const [
      ourBrands,
      news,
      barUsps,
      productGroups
    ] = await Promise.all([
      wordpress.get('brands'),
      wordpress.get(app.i18n.t('endpoints.cat_news')),
      wordpress.get(app.i18n.t('endpoints.bar_usps')),
      portal.get(`productgroups?lang=${language}`)
    ])
    commit('SET_BRANDS', ourBrands.data)
    commit('SET_NEWS', news.data)
    commit('SET_BAR_USPS', barUsps.data)

    const newProductGroups = []
    let universal = {}

    // ID 5 for "parts by category" - Used to be Universal
    const univeralId = 5
    for (let i = 0; i < productGroups.data.length; i++) {
      const productGroup = productGroups.data[i]
      for (let j = 0; j < productGroups.data[i].translations.length; j++) {
        if (
          productGroups.data[i].translations[j].language.language_code === 'en'
        ) {
          productGroup.fallback_name =
            productGroups.data[i].translations[j].name
          if (productGroups.data[i].id === univeralId) {
            universal = productGroups.data[i]
          }
        }
        if (
          productGroups.data[i].translations[j].language.language_code ===
          language
        ) {
          productGroup.name = productGroups.data[i].translations[j].name
        }
      }
      newProductGroups.push(productGroup)
    }
    commit('SET_PRODUCT_GROUPS', newProductGroups)

    const [brands] = await Promise.all([
      portal.get(`productgroups/${univeralId}?lang=${language}`)
    ])
    const newBrands = []
    for (let i = 0; i < brands.data[0].brands.length; i++) {
      const brand = brands.data[0].brands[i]
      if (brands.data[0].brands[i] !== null) {
        for (let j = 0; j < brands.data[0].brands[i].translations.length; j++) {
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            'en'
          ) {
            brand.fallback_name = brands.data[0].brands[i].translations[j].name
          }
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            language
          ) {
            brand.name = brands.data[0].brands[i].translations[j].name
          }
        }
        newBrands.push(brand)
      }
    }
    for (let j = 0; j < universal.translations.length; j++) {
      if (universal.translations[j].language.language_code === 'en') {
        universal.fallback_name = universal.translations[j].name
      }
      if (universal.translations[j].language.language_code === language) {
        universal.name = universal.translations[j].name
      }
    }
    commit('SET_UNIVERSAL_MAIN', universal)
    commit('SET_UNIVERSAL', newBrands)

    // GET XML STOCK (TEMP)
    /* const stockUrl = 'https://www.apairltd.com/xml/pricefile20200526103755.xml'
    const [stockXml] = await Promise.all([this.$axios.get(stockUrl)])
    const stockJson = convert.xml2json(stockXml.data, {
      compact: false,
      spaces: 4
    })
    commit('SET_STOCK', stockJson) */

    if (app.$auth.$state.loggedIn) {
      commit('SET_BEARER', app.$cookies.get('apa__token_local'))
    }
  },

  setCartStatus({ commit }, value) {
    commit('SET_CART_STATUS', value)
  },

  fetchLocalStorage({ commit }) {
    if (window.localStorage.getItem('cartItems') !== null) {
      commit(
        'SET_CART_ITEMS',
        JSON.parse(window.localStorage.getItem('cartItems'))
      )
    }
  },

  async setLanguage({ commit, state }, language) {
    commit('SET_LANGUAGE', language)

    let currentPriceList = getUserPriceList(language, this.app.$auth);
    let currentRetailPriceList = currentPriceList;

    if (this.app.$auth.loggedIn) {
      currentRetailPriceList = getRetailPriceList(language, currentPriceList);
    }

    commit('SET_PRICE_LIST', currentPriceList)
    commit('SET_RETAIL_PRICE_LIST', currentRetailPriceList);

    const [news, barUsps, productGroups] = await Promise.all([
      wordpress.get(this.app.i18n.t('endpoints.cat_news')),
      wordpress.get(this.app.i18n.t('endpoints.bar_usps')),
      portal.get(`productgroups?lang=${language}`)
    ])
    commit('SET_NEWS', news.data)
    commit('SET_BAR_USPS', barUsps.data)

    const newProductGroups = []
    let universal = {}
    for (let i = 0; i < productGroups.data.length; i++) {
      const productGroup = productGroups.data[i]
      for (let j = 0; j < productGroups.data[i].translations.length; j++) {
        if (
          productGroups.data[i].translations[j].language.language_code === 'en'
        ) {
          productGroup.fallback_name =
            productGroups.data[i].translations[j].name
          if (productGroups.data[i].translations[j].id === 5) {
            universal = productGroups.data[i]
          }
        }
        if (
          productGroups.data[i].translations[j].language.language_code ===
          language
        ) {
          productGroup.name = productGroups.data[i].translations[j].name
        }
      }
      newProductGroups.push(productGroup)
    }
    commit('SET_PRODUCT_GROUPS', newProductGroups)

    // ID 5 for "parts by category" - Used to be Universal
    const [brands] = await Promise.all([
      portal.get(`productgroups/5?lang=${language}`)
    ])
    const newBrands = []
    for (let i = 0; i < brands.data[0].brands.length; i++) {
      const brand = brands.data[0].brands[i]
      if (brands.data[0].brands[i] !== null) {
        for (let j = 0; j < brands.data[0].brands[i].translations.length; j++) {
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            'en'
          ) {
            brand.fallback_name = brands.data[0].brands[i].translations[j].name
          }
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            language
          ) {
            brand.name = brands.data[0].brands[i].translations[j].name
          }
        }
        newBrands.push(brand)
      }
    }
    for (let j = 0; j < universal.translations.length; j++) {
      if (universal.translations[j].language.language_code === 'en') {
        universal.fallback_name = universal.translations[j].name
      }
      if (universal.translations[j].language.language_code === language) {
        universal.name = universal.translations[j].name
      }
    }
    commit('SET_UNIVERSAL_MAIN', universal)
    commit('SET_UNIVERSAL', newBrands)
  }
}

function getUserPriceList (language, authObj) {
  let priceList = 16;

  if (language === 'ie') {
    priceList = 1;
  } else if (language !== 'en') {
    priceList = 26;
  }

  if (authObj.loggedIn) {
    priceList = authObj.user.customer_type_id;
  }

  return priceList;
}

function getRetailPriceList (language, userPriceList) {
  let basePriceList = 16;
  if (language === 'ie') {
    basePriceList = 1;
  } else if (language !== 'en') {
    basePriceList = 26;
  }

  if (!userPriceList) {
    return basePriceList;
  }

  if (userPriceList >= 26) {
    return 26;
  }

  return basePriceList;
}
